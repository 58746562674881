import cookie from "cookie";

export function parseCookies(req) {
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
}

export function setGoogleAdwordsInfo(values) {
  if (window.globalEmail && window.globalPhone) return;
  window.globalEmail = values.email;
  window.globalPhone = `1${values.phone}`;
  return
}

export function saveToLocalStorage(key, obj, finallyCallback) {
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (e) {
    alert("You are in private browsing mode and your data may not be saved.");
  } finally {
    if (finallyCallback) finallyCallback();
  }
}
