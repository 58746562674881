import styled from "styled-components";

const ModalBody = styled.div`
  min-height: 580px;
  padding: 0px;

  @media only screen and (max-width: 768px) {
    overflow-y: auto;
    padding: 0px;
  }
`;

export default ModalBody;
