import { ModalLayout, ModalContainer } from "./styled";

export default function Modal({ visible, children, styles }) {
  return (
    <ModalLayout
      visible={visible}
      closable={false}
      centered
      footer={null}
      mask={true}
      style={{ maxWidth: "763px", width: "100%", ...(styles?.layout || {}) }}
    >
      <ModalContainer style={styles?.container || {}}>
        {children}
      </ModalContainer>
    </ModalLayout>
  );
}
