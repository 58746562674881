import styled from "styled-components";
import { Modal } from "antd";

import PInput from "../../components/PInput";
import PTextArea from "../../components/PTextArea";
import PButton from "../../components/PButton";

export const CustomModal = styled(Modal)`
  width: fit-content !important;
  & {
    .ant-modal-body {
      padding: 0px;
    }
  }

  @media only screen and (max-width: 560px) {
    max-width: 100vw;
    padding: 0px;
    margin: 0px;
    height: calc(100vh - 51px);
    width: 100vw !important;
    top: 25px !important;
  }
`;

export const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 560px) {
    bottom: 0px;
    align-items: flex-end;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 900px;
  padding: 60px;
  background-color: ${({ theme }) => theme.colors.lightGray2};
  box-shadow: 0px 3px 6px #00000065;

  #dialog-desc {
    text-align: center;
    color: black;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  @media only screen and (max-width: 560px) {
    width: 100vw;
    height: calc(100vh - 51px);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 88px;
  }

  @media only screen and (max-width: 370px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ElementWrap = styled.div`
  padding-bottom: ${({ theme, pbottom }) =>
    pbottom ? pbottom : theme.spacings.spacing_xs};
  padding-right: ${({ theme, pright }) =>
    pright ? pright : theme.spacings.spacing_xs};
  padding-left: ${({ theme, pleft }) =>
    pleft ? pleft : theme.spacings.spacing_xs};
  ${({ ptop }) => ptop && `padding-top: ${ptop}`};
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  justify-content: center;
`;

export const InlineElements = styled.div`
  display: flex;
`;

export const SubmitButton = styled(PButton)`
  height: 56px;
  width: 293px;
  box-shadow: 0px 3px 6px #00000065;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.prune};
  border: 0px;
  margin-top: 34px;

  & span {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.white};
  }

  @media only screen and (max-width: 560px) {
    width: 100%;
    margin-top: 30px;
    & span {
      font-size: 15px;
      letter-spacing: 1.2px;
    }
  }
`;

export const TitleText = styled.div`
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 1.04px;
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 20px;
  text-align: center;

  @media only screen and (max-width: 560px) {
    font-size: 20px;
    letter-spacing: 0.8px;
    max-width: 294px;
  }

  @media only screen and (max-width: 370px) {
    font-size: 18px;
  }
`;

export const CustomInput = styled(PInput)`
  font-size: 14px;
  height: 40px;
  &::placeholder {
    font-size: 14px;
  }

  @media only screen and (max-width: 560px) {
    font-size: 12px;
    height: 30px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const CTextArea = styled(PTextArea)`
  font-size: 14px;
  height: 40px;
  &::placeholder {
    font-size: 14px;
  }
  @media only screen and (max-width: 560px) {
    font-size: 12px;
    height: 30px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const CloseBtn = styled.button`
  height: 42px;
  width: 42px;
  position: absolute;
  right: 5%;
  top: 4%;
  font-size: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  &:hover {
    color: black;
  }
`;

export const MinimizedPopup = styled.button`
border-radius: 30%;
height: 90px;
width: 90px;
background-color: ${({ theme }) => theme.colors.lightGray2};
position: fixed;
bottom: 3%;
right: 5%;
z-index: 99;
box-shadow: 0px 0px 2px .3px rgba(0,0,0,.6);
display: flex;
justify-content: center;
align-items: center;
&:hover {
  cursor: pointer;
  transform: scale(1.15);
}
@media (max-width: 768px) {
  height: 120px;
  width: 120px;
}
@media (max-width: 560px) {
  height: 90px;
  width: 90px;
}

@media (prefers-reduced-motion: reduce) {
  transform: scale(1.1);
}

& .img {
  flex: 1 1;
  text-align: center;
  width: 120%;
}
`;
