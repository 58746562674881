import styled, { css } from "styled-components";
import { CustomModal } from "../../SchedulePopup/styled";

export const ModalContainer = styled.div`
  ${({ theme, hideBorder }) => css`
    background-color: ${theme.colors.white};
    border: ${hideBorder ? "none" : "none"};

    @media only screen and (max-width: 768px) {
      height: auto;
      width: 100%;
      transform: none;
      top: 0px;
      left: 0px;
      padding: 0px;
    }
  `}
`;

export const ModalLayout = styled(CustomModal)`
  max-width: 793px;
  width: 100% !important;
  height: auto;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 96%;
    padding: 0px;
    margin:-0;
  }
`;
